
import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import { SET_CURRENT } from '@/store/mutation-types';

@Component({
  computed: { ...mapState(['loading']), ...mapGetters(['filteredMosaics']) },
})
export default class IndexTable extends Vue {
  protected onRowClicked(row: any, col: any, cell: any) {
    this.$store.commit(SET_CURRENT, row.guid);
  }
}
