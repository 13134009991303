
import { SET_CURRENT } from '@/store/mutation-types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  computed: mapGetters(['filteredMosaics']),
})
export default class MosaicCardList extends Vue {
  @Prop({default: 'List'})
  public listViewKind!: string;

  protected showDetailsModal( gid: string) {
    if (gid !== null) {
      this.$store.commit(SET_CURRENT, gid);
    }
  }
}
