
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseLayout from './BaseLayout.vue';

@Component({
})
export default class BrowserLayout extends BaseLayout {
  public created() {
    const dismissWelcome = localStorage.getItem('dismissWelcome');
    if ( !dismissWelcome ) {
      this.$store.commit('setTopic', 'about-welcome' );
    }
  }
}
